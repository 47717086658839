import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import io from "socket.io-client";
import { ROUTES } from './routes/routes';
import ContentLoader from './components/ContentLoader';
import "bootstrap-icons/font/bootstrap-icons.css";
import { getAuthDetails } from './utilities';
import { APP_SOCKET } from './constants';

class App extends React.Component {

  componentDidMount() {
    this.connectSocket();
  }

  connectSocket = () => {
    const SOCKET_SERVER_URL = APP_SOCKET; // ✅ Replace with your actual backend URL
    const userData = getAuthDetails();

    if (userData && userData.token) {
      if (window.socket) {
        console.log("⚠️ Socket already initialized.");
        return; // ✅ Prevent multiple socket instances
      }

      // ✅ Create socket connection
      window.socket = io(SOCKET_SERVER_URL, {
        query: {
          token: userData.token,
        },
        transports: ["websocket", "polling"],
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      // ✅ Event listeners
      window.socket.on("connect", () => {
        console.log("✅ Connected to socket server");
      });

      window.socket.on("disconnect", (reason) => {
        console.warn("⚠️ Disconnected from socket server:", reason);
      });

      window.socket.on("connect_error", (error) => {
        console.error("❌ Connection error:", error.message);
      });
    }
  };

  render() {
    return (
      <Router>
        <Suspense fallback={<ContentLoader />}>
          <Routes>
            <Route {...ROUTES.PAGE_ROUTES} />
          </Routes>
        </Suspense>
      </Router>
    );
  }
}

export default App;
