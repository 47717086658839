export const API_BASE = process.env.REACT_APP_API_ROOT;
export const API_URL = API_BASE;
export const APP_SOCKET = process.env.REACT_APP_SOCKET;
export const GOOGLE_CLIENT_ID = '311249512401-j3a25v82omoiu7qor8u6rt0jqg0ujcvj.apps.googleusercontent.com';
export const WEBSITE_URL = 'https://dev.whaot.com';
export const STRIPE = 'pk_test_51NM3q4SIHQ2eI7Ni8GSOa7vLa3Rn0wx4QHrxyBHlGoKyJGtCpXzNSr7F8qwCqLFBF9jCNna2C9IPZtxJCXtGt8Eb00xloXiXna';
export const RAZORPAY = "rzp_test_HZVGvRzMAmxJ0A";
export const INVITE_CODE_LENGTH = 8;
export const JITSI_MEET_URL = "jitsi-dev-1.whaot.com";
export const DEFAULT_LECTURE_DURATION_IN_MINSUTES = 50;
export const sitekey = "6LdtRwUrAAAAAHX-rmsMVLFUv4oaqC37-VA8zgw1";